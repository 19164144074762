import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "./style";
import AccessHolderPanel from "../../Panels/AccessHolder";
import AccessHolderStatusPanel from "../../Panels/AccessHolderStatus";
import CredentialsPanel from "../../Panels/Credentials";
import VehiclePanel from "../../Panels/Vehicles";
import FlexibleParkingAccountPanel from "../../Panels/FlexibleParkingAccount";
import useHasPermissions from "../../../hooks/useHasPermissions";
import clsx from "clsx";
import AccessGroups from "../../Panels/AccessGroups";
import HotelDetailsPanel from "../../Panels/HotelDetails";
import GroupContractDetailsPanel from "../../Panels/GroupContracts/GroupContractDetails/index";
import { useFeatureFlag } from "../../../hooks/useFeatureFlags";

const AccessHolderForm = ({
  accessHolderID,
  facilityID,
  onChange,
  onDelete,
  onViewGroupContract,
}) => {
  const classes = useStyles();
  const [isInGroupContract, setIsInGroupContract] = React.useState(false);
  const [accessHolder, setAccessHolder] = React.useState({
    accessHolderID,
  });
  const [accessHolderEmail, setAccessHolderEmail] = React.useState();
  const { hasPermissions } = useHasPermissions();
  const credentialsRead = hasPermissions(
    ["accessholders.view", "inventory.view"],
    true
  );
  const accessGroupRead = hasPermissions(["accessgroups.view"]);
  const accessHolderEdit = hasPermissions(["accessholders.edit"]);
  const accessHolderView = hasPermissions(["accessholders.view"]);
  const accessCredentialEdit = hasPermissions(
    ["accessholders.edit", "inventory.edit"],
    true
  );
  const accessCredentialAdd = hasPermissions(
    ["accessholders.edit", "inventory.add", "accessholders.add"],
    true
  );

  const handleAccessHolderChange = (accessHolder) => {
    setAccessHolder(accessHolder);
    setAccessHolderEmail(accessHolder?.contactInfo?.emails?.[0]?.emailAddress);
    onChange(accessHolder);
  };

  const handleAccessHolderDelete = () => {
    setAccessHolder({});
    setAccessHolderEmail("");
    onDelete();
  };

  const hotelIntegration = useFeatureFlag("Hotel Integrations");
  const flexibleParkingAccount = useFeatureFlag("Flexible Parking Account");

  return (
    <div className={clsx(classes.root)}>
      <AccessHolderPanel
        className={clsx(classes.accessHolderPanel, classes.flexChild)}
        accessHolderID={accessHolder.accessHolderID}
        onChange={handleAccessHolderChange}
        onDelete={handleAccessHolderDelete}
      />
      {hotelIntegration && (
        <HotelDetailsPanel
          className={clsx(classes.hotelDetails, classes.flexChild)}
          accessHolderID={accessHolder.accessHolderID}
          entityID={facilityID}
        />
      )}
      <AccessHolderStatusPanel
        className={clsx(classes.accessHolderStatus, classes.flexChild)}
        accessHolderID={accessHolder.accessHolderID}
        contractID={accessHolder?.contractID}
      />
      {credentialsRead && (
        <CredentialsPanel
          className={clsx(classes.credentials, classes.flexChild)}
          accessHolderEmail={accessHolderEmail}
          accessHolderID={accessHolder.accessHolderID}
          contractID={accessHolder.contractID}
          facilityID={facilityID}
          disabled={
            (!accessCredentialEdit && !accessCredentialAdd) ||
            !accessHolder.accessHolderID
          }
        />
      )}
      {accessGroupRead && (
        <AccessGroups
          className={clsx(classes.accessGroupPanel, classes.flexChild)}
          mode="assign"
          accessHolderID={accessHolder.accessHolderID}
          disabled={!accessHolder.accessHolderID}
        />
      )}
      <GroupContractDetailsPanel
        className={clsx(classes.groupContractDetailsPanel, classes.flexChild)}
        contractID={accessHolder?.contractID}
        onViewGroupContract={onViewGroupContract}
        setIsGroupContract={setIsInGroupContract}
      />
      {flexibleParkingAccount && !isInGroupContract && (
        <FlexibleParkingAccountPanel
          className={clsx(classes.flexibleParkingAccountPanel, classes.flexChild)}
          disabled={!accessHolderEdit || !accessHolder.accessHolderID}
          accessHolderID={accessHolder.accessHolderID}
          facilityID={facilityID}
          contractID={accessHolder.contractID}
          viewPermission={accessHolderView}
        />
      )}

      <VehiclePanel
        className={clsx(classes.vehiclePanel, classes.flexChild)}
        disabled={!accessHolderEdit || !accessHolder.accessHolderID}
        accessHolderID={accessHolder.accessHolderID}
        facilityID={facilityID}
        contractID={accessHolder.contractID}
        assignedAccessHolderName={{
          first: accessHolder?.contactInfo?.firstName,
          last: accessHolder?.contactInfo?.lastName,
        }}
      />
    </div>
  );
};

AccessHolderForm.defaultProps = {
  onChange: () => {},
  onDelete: () => {},
};

AccessHolderForm.propTypes = {
  accessHolderID: PropTypes.string,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default AccessHolderForm;
